import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import lottie from "lottie-web";

import { Card, Modal } from "react-bootstrap";
import HomeHero from "../components/home-hero";
import StarLogo from "../images/icons/star.svg";
import FacebookLogo from "../images/bi_facebook.svg";
import XLogo from "../images/logo 1.svg";
import InstaLogo from "../images/Frame 62.svg";
import ContactForm from "../components/contactForm";
import HeroOther from "../components/hero-other";
import ReactPlayer from "react-player";
import ImageCarousel from "../components/changingImage";

const CourseOverviewPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Overview" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img1: wpMediaItem(title: { eq: "Pantry" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img2: wpMediaItem(title: { eq: "Tanishq" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img3: wpMediaItem(title: { eq: "Contact-New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery1: wpMediaItem(title: { eq: "index-4" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			gallery2: wpMediaItem(title: { eq: "index-5" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			gallery3: wpMediaItem(title: { eq: "index-6" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery4: wpMediaItem(title: { eq: "index-7" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery5: wpMediaItem(title: { eq: "index-8" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery6: wpMediaItem(title: { eq: "index-9" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery7: wpMediaItem(title: { eq: "index-10" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery1b: wpMediaItem(title: { eq: "new2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery2b: wpMediaItem(title: { eq: "new5" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery3b: wpMediaItem(title: { eq: "new7" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery4b: wpMediaItem(title: { eq: "new9" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery5b: wpMediaItem(title: { eq: "new13" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery6b: wpMediaItem(title: { eq: "new3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery7b: wpMediaItem(title: { eq: "new1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			secondgallery1: wpMediaItem(title: { eq: "Gallery Pantry 1 1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			secondgallery2: wpMediaItem(title: { eq: "Gallery Pantry 1 2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			secondgallery3: wpMediaItem(title: { eq: "Gallery Pantry 2 1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			secondgallery4: wpMediaItem(title: { eq: "Gallery Pantry 2 2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			secondgallery5: wpMediaItem(title: { eq: "Gallery Pantry 3 1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			secondgallery6: wpMediaItem(title: { eq: "Gallery Mix 1 3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			secondgallery7: wpMediaItem(title: { eq: "Gallery Pantry 3 3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			thirdgallery1: wpMediaItem(title: { eq: "overview-9" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			thirdgallery2: wpMediaItem(title: { eq: "overview-10" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			thirdgallery3: wpMediaItem(title: { eq: "overview-11" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			thirdgallery4: wpMediaItem(title: { eq: "overview-12" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			thirdgallery5: wpMediaItem(title: { eq: "overview-13" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			thirdgallery6: wpMediaItem(title: { eq: "overview-14" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			thirdgallery7: wpMediaItem(title: { eq: "overview-15" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const [showModal, setShowModal] = useState(false);

	const handleButtonClick = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const [currentSlides, setCurrentSlides] = useState([0, 0, 0, 0, 0, 0, 0]);

	const updateSlides = (index) => {
		setCurrentSlides((prevSlides) => {
			const updatedSlides = [...prevSlides];
			updatedSlides[index] = updatedSlides[index] === 1 ? 0 : 1;
			return updatedSlides;
		});

		setTimeout(() => {
			const nextIndex = (index + 1) % currentSlides.length;
			updateSlides(nextIndex);
		}, 1500);
	};

	useEffect(() => {
		updateSlides(0);
	}, []);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Course Overview",
				item: {
					url: `${siteUrl}/course-overview`,
					id: `${siteUrl}/course-overview`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Course Overview | Toot Hill Golf Course"
				description="We are delighted to welcome to our renowned parkland golf course, undoubtably one of the finest in Essex."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/course-overview`,
					title: "Course Overview | Toot Hill Golf Course",
					description:
						"We are delighted to welcome to our renowned parkland golf course, undoubtably one of the finest in Essex.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<HeroOther
						smallHeading="Where we are"
						title="Course overview"
						description={
							<>
								Located just off the M11 and M25 this undulating parkland
								<br className="d-none d-md-block" /> course is undoubtedly one
								of the finest in Essex.
							</>
						}
						externalLink
						button1Text="Reserve a Tee Time"
						button1Link="https://bookings.toothillgolfclub.co.uk/"
						button2Text="Get in touch"
						button2Link="/contact-us"
						backgroundImage={
							data.heroImg.localFile.childImageSharp.gatsbyImageData
						}
					/>
					<section className="py-4 py-md-5 py-lg-7 bg-primary">
						<Container>
							<Row className="justify-content-center">
								<Col className="text-center" lg={8}>
									<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
										what to expect
									</p>
									<h2 className="line-heading text-white mb-4 fs-1">
										Not just a golf club
									</h2>
									<p className="text-white text-center">
										Although our golf is world class it won’t be the only thing
										you’ll be able to enjoy when you visit Toot Hill. No round
										would be complete without a visit to Tanishq, our
										sensational restaurant and bar.
									</p>
									<p className="text-white text-center">
										<strong className="fw-bold">Coming soon!</strong> We are
										constructing several beautiful on site apartments for those
										who wish to step out onto the course without the need
										without the stress of a long journey beforehand.
									</p>

									<Button
										style={{ borderRadius: "0px" }}
										variant="white"
										as={Link}
										to="/contact-us"
										className="py-3 w-100 text-primary w-md-auto mb-4 mb-md-0 mt-4 px-6 me-md-4 "
									>
										Get in touch
									</Button>
									{/* <Button
                    style={{ borderRadius: "0px" }}
                    variant="outline-white"
                    as={Link}
                    to="/"
                    className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-4 px-6 "
                  >
                    Scorecard
                  </Button> */}
									<Button
										style={{ borderRadius: "0px" }}
										variant="outline-white"
										onClick={handleButtonClick}
										className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-4 px-6"
									>
										Scorecard
									</Button>

									<Modal show={showModal} onHide={handleCloseModal}>
										{/* Modal content */}
										<Modal.Header closeButton>
											<Modal.Title>Scorecard</Modal.Title>
										</Modal.Header>
										<Modal.Body>{/* Add your modal content here */}</Modal.Body>
										<Modal.Footer>
											{/* Add your modal footer here */}
										</Modal.Footer>
									</Modal>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-4 py-md-5 py-lg-7">
						<Container>
							<Row>
								<Col lg={6}>
									<h2 className="text-primary mb-4 fs-1">
										Welcome to Toot Hill Golf Club
									</h2>
									<p className="mb-4">
										We are delighted to welcome to our renowned parkland golf
										course, undoubtably one of the finest in Essex.
									</p>
								</Col>
							</Row>
							<Row>
								<Col>
									<ReactPlayer
										controls
										className="w-100 h-auto"
										url="https://toot-hill-golf.rjmdigital.net/wp-content/uploads/2023/10/Toot-Hill-Golf-Club-Promo-Final.mp4"
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-4 py-md-5 py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary mb-4 fs-1">The course</h2>
									<p className="mb-4">
										Our golf course is an all-year-round gem, welcoming golfers
										of all abilities. With a par of 70, it provides an enjoyable
										challenge for both newcomers and seasoned players.
										Impeccably maintained fairways and greens ensure an
										exceptional golfing experience all year round. Join us for a
										round anytime and relish the timeless joy of golf.
									</p>
								</Col>
							</Row>
							<Row className="d-none d-md-flex">
								<Col md={6} lg={4} className="g-3">
									<ImageCarousel
										images={[data.gallery1, data.gallery1b]}
										height="310px"
										activeslide={currentSlides[0]}
									/>
									<ImageCarousel
										images={[data.gallery2, data.gallery2b]}
										height="310px"
										activeslide={currentSlides[1]}
									/>
									<ImageCarousel
										images={[data.gallery3, data.gallery3b]}
										height="310px"
										activeslide={currentSlides[2]}
									/>
								</Col>
								<Col md={6} lg={4} className="g-3">
									<ImageCarousel
										images={[data.gallery4, data.gallery4b]}
										height="310px"
										activeslide={currentSlides[3]}
									/>
									<ImageCarousel
										images={[data.gallery5, data.gallery5b]}
										height="636px"
										activeslide={currentSlides[4]}
									/>
								</Col>
								<Col lg={4} className="g-3 d-none d-lg-block">
									<ImageCarousel
										images={[data.gallery6, data.gallery6b]}
										height="636px"
										activeslide={currentSlides[5]}
									/>
									<ImageCarousel
										images={[data.gallery7, data.gallery7b]}
										height="310px"
										activeslide={currentSlides[6]}
									/>
								</Col>
							</Row>
							<Row className=" d-md-none">
								<Col xs={6} lg={4} className="g-3">
									<GatsbyImage
										style={{ height: "336px" }}
										className="w-100 mb-3 d-lg-none"
										image={
											data.gallery6.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery6.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.gallery1.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery1.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.gallery2.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery2.altText}
									/>
								</Col>
								<Col xs={6} lg={4} className="g-3">
									<GatsbyImage
										className="w-100 mb-3 d-lg-none"
										style={{ height: "160px" }}
										image={
											data.gallery3.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery3.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.gallery4.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery4.altText}
									/>
									<GatsbyImage
										style={{ height: "336px" }}
										className="w-100 mb-3"
										image={
											data.gallery5.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery5.altText}
									/>
								</Col>
							</Row>
						</Container>
					</section>

					{/* <section className="py-4 py-md-5 py-lg-7">
            <Container>
              <Row>
                <Col lg={6}>
                  <h2 className="text-primary mb-4 fs-1">The course</h2>
                  <p className="mb-4">
                    Our golf course is an all-year-round gem, welcoming golfers
                    of all abilities. With a par of 70, it provides an enjoyable
                    challenge for both newcomers and seasoned players.
                    Impeccably maintained fairways and greens ensure an
                    exceptional golfing experience all year round. Join us for a
                    round anytime and relish the timeless joy of golf.
                  </p>
                </Col>
              </Row>
              <Row className="d-none d-md-flex">
                <Col md={6} lg={4} className="g-3">
                  <GatsbyImage
                    style={{ height: "636px" }}
                    className="w-100 mb-3 d-lg-none"
                    image={
                      data.gallery6.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery6.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "310px" }}
                    image={
                      data.gallery1.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery1.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "310px" }}
                    image={
                      data.gallery2.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery2.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3 d-none d-lg-block"
                    style={{ height: "310px" }}
                    image={
                      data.gallery3.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery3.altText}
                  />
                </Col>
                <Col md={6} lg={4} className="g-3">
                  <GatsbyImage
                    className="w-100 mb-3 d-lg-none"
                    style={{ height: "310px" }}
                    image={
                      data.gallery3.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery3.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "310px" }}
                    image={
                      data.gallery4.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery4.altText}
                  />
                  <GatsbyImage
                    style={{ height: "636px" }}
                    className="w-100 mb-3"
                    image={
                      data.gallery5.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery5.altText}
                  />
                </Col>
                <Col lg={4} className="g-3 d-none d-lg-block">
                  <GatsbyImage
                    style={{ height: "636px" }}
                    className="w-100 mb-3"
                    image={
                      data.gallery6.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery6.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "310px" }}
                    image={
                      data.gallery7.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery7.altText}
                  />
                </Col>
              </Row>
              <Row className=" d-md-none">
                <Col xs={6} lg={4} className="g-3">
                  <GatsbyImage
                    style={{ height: "336px" }}
                    className="w-100 mb-3 d-lg-none"
                    image={
                      data.gallery6.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery6.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "160px" }}
                    image={
                      data.gallery1.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery1.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "160px" }}
                    image={
                      data.gallery2.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery2.altText}
                  />
                </Col>
                <Col xs={6} lg={4} className="g-3">
                  <GatsbyImage
                    className="w-100 mb-3 d-lg-none"
                    style={{ height: "160px" }}
                    image={
                      data.gallery3.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery3.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "160px" }}
                    image={
                      data.gallery4.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery4.altText}
                  />
                  <GatsbyImage
                    style={{ height: "336px" }}
                    className="w-100 mb-3"
                    image={
                      data.gallery5.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery5.altText}
                  />
                </Col>
              </Row>
            </Container>
          </section> */}
					<section className="py-4 py-md-5 py-lg-7">
						<Container>
							<Row>
								<Col lg={6}>
									<h2 className="text-primary mb-4 fs-1">The Pantry</h2>
									<p className="mb-4">
										Our newly opened Pantry with it’s delicious range of freshly
										prepared food will be there before or after your game.
									</p>
								</Col>
							</Row>
							<Row className="d-none d-md-flex">
								<Col md={6} lg={4} className="g-3">
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "310px" }}
										image={
											data.secondgallery1.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery1.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "636px" }}
										image={
											data.secondgallery2.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery2.altText}
									/>
									<GatsbyImage
										style={{ height: "310px" }}
										className="w-100 mb-3 d-lg-none"
										image={
											data.secondgallery6.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery6.altText}
									/>
								</Col>
								<Col md={6} lg={4} className="g-3">
									<GatsbyImage
										className="w-100 mb-3 "
										style={{ height: "636px" }}
										image={
											data.secondgallery3.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery3.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "310px" }}
										image={
											data.secondgallery4.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery4.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3 d-lg-none"
										style={{ height: "310px" }}
										image={
											data.secondgallery7.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery7.altText}
									/>
								</Col>
								<Col lg={4} className="g-3 d-none d-lg-block">
									<GatsbyImage
										style={{ height: "310px" }}
										className="w-100 mb-3"
										image={
											data.secondgallery5.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery5.altText}
									/>
									<GatsbyImage
										style={{ height: "310px" }}
										className="w-100 mb-3"
										image={
											data.secondgallery6.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery6.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "310px" }}
										image={
											data.secondgallery7.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery7.altText}
									/>
								</Col>
							</Row>
							<Row className=" d-md-none">
								<Col xs={6} lg={4} className="g-3">
									<GatsbyImage
										style={{ height: "160px" }}
										className="w-100 mb-3 d-lg-none"
										image={
											data.secondgallery6.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery6.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.secondgallery1.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery1.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "336px" }}
										image={
											data.secondgallery2.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery2.altText}
									/>
								</Col>
								<Col xs={6} lg={4} className="g-3">
									<GatsbyImage
										className="w-100 mb-3 d-lg-none"
										style={{ height: "336px" }}
										image={
											data.secondgallery3.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery3.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.secondgallery4.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery4.altText}
									/>
									<GatsbyImage
										style={{ height: "160px" }}
										className="w-100 mb-3"
										image={
											data.secondgallery5.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.secondgallery5.altText}
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-4 py-md-5 py-lg-7">
						<Container>
							<Row>
								<Col lg={6}>
									<h2 className="text-primary mb-4 fs-1">Tanishq</h2>
									<p className="mb-4">
										Tanishq is a modern Indian fine dining restaurant situated
										in the Toot Hill grounds offering a refined culinary
										experience that highlights the best of Indian cuisine.
									</p>
								</Col>
							</Row>
							<Row className="d-none d-md-flex">
								<Col md={6} lg={4} className="g-3">
									<GatsbyImage
										style={{ height: "310px" }}
										className="w-100 mb-3 d-lg-none"
										image={
											data.thirdgallery6.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery6.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "310px" }}
										image={
											data.thirdgallery1.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery1.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "636px" }}
										image={
											data.thirdgallery2.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery2.altText}
									/>
								</Col>
								<Col md={6} lg={4} className="g-3">
									<GatsbyImage
										className="w-100 mb-3 "
										style={{ height: "636px" }}
										image={
											data.thirdgallery3.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery3.altText}
									/>

									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "310px" }}
										image={
											data.thirdgallery4.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery4.altText}
									/>
									<GatsbyImage
										style={{ height: "310px" }}
										className="w-100 mb-3 d-lg-none"
										image={
											data.thirdgallery5.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery5.altText}
									/>
								</Col>
								<Col lg={4} className="g-3 d-none d-lg-block">
									<GatsbyImage
										style={{ height: "310px" }}
										className="w-100 mb-3"
										image={
											data.thirdgallery5.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery5.altText}
									/>
									<GatsbyImage
										style={{ height: "310px" }}
										className="w-100 mb-3"
										image={
											data.thirdgallery6.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery6.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "310px" }}
										image={
											data.thirdgallery7.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery7.altText}
									/>
								</Col>
							</Row>
							<Row className=" d-md-none">
								<Col xs={6} lg={4} className="g-3">
									<GatsbyImage
										style={{ height: "160px" }}
										className="w-100 mb-3 d-lg-none"
										image={
											data.thirdgallery6.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery6.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.thirdgallery1.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery1.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "336px" }}
										image={
											data.thirdgallery2.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery2.altText}
									/>
								</Col>
								<Col xs={6} lg={4} className="g-3">
									<GatsbyImage
										className="w-100 mb-3 d-lg-none"
										style={{ height: "336px" }}
										image={
											data.thirdgallery3.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery3.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.thirdgallery4.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery4.altText}
									/>
									<GatsbyImage
										style={{ height: "160px" }}
										className="w-100 mb-3"
										image={
											data.thirdgallery5.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.thirdgallery5.altText}
									/>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default CourseOverviewPage;
